@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

.card{

    background-color: #F5FCE7;
    border-radius: 20px;
    font-family: 'Nexa Bold';
    padding: 30px;
    align-items: center;
    height: 400px;
    justify-content: center;

}

.arrow4 {
  display: none;

}

@media screen and (max-width: 768px){

  .hero-text{
    text-align: right;
  }

    .whoweare{
        width: 100%;
        margin: 0;
        
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100px;


        
    }

    .whoweare img {
        height: 50px;
        width: auto;
        align-self: auto;
        margin-left: auto;


    }

    .cardcontainer{
        flex-direction: column;
    }

    .cardcontainer2{
        flex-direction: column;
    }

    .cardcontainer2 .card{
        width: 100%;
        margin: 0;
        margin-bottom: 10px;
    }

    .footer{
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
    }

    .footer p{
        margin:0px;
    }

    .footer img{
        margin:0;
        margin-top: 10px;
        
    }


    .hero2 {
        padding: 0 0px;
        margin-bottom: 100px;
    }
    .herocontainer2{
        padding: 0px;
        flex-direction: column;
        margin-top: 140px;
    }
   
    .arrow4{
      display: block;
      height: 150px;
  
      }
}
 


@media screen and (min-width: 2300px){
    .card h2{
        font-size: 2rem;
        line-height: 1.5;

    }
}


@keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  .fade-in {
    animation: fadeIn 2s ease-in;
  }

  
  @keyframes slideInFromLeft {
    from {
      transform: translateX(-100%);
    }
    to {
      transform: translateX(0);
    }
  }
  
  .slide-in-left {
    animation: slideInFromLeft 1s ease-out;
  }

  @keyframes slideInFromRight {
    from {
      transform: translateX(100%);
    }
    to {
      transform: translateX(0);
    }
  }
  
  .slide-in-right {
    animation: slideInFromRight 1s ease-out;
  }

  
  @keyframes rotate {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  
  .rotate {
    animation: rotate 1s linear;
  }
  