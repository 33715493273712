/* page.css */
.blog-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-height: 100vh; /* Ensure the page takes at least the full height of the viewport */
   /*background-color: #f4f4f4;*/
}

.blog-title {
  font-size: 2rem;
  text-align: center;
  margin-bottom: 40px;
}

.blog-list {
  display: flex;
  flex-direction: column;
  align-items: center; /* Center all blog posts */
  width: 100%;
  max-width: 800px; /* Maximum width to avoid posts becoming too wide */
  flex-grow: 1; /* Make the blog list take the remaining space */
}

.blog-post {
  background-color: #fff;
  border-radius: 8px;
  overflow: hidden;
  /*box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);*/
  width: 100%;
  margin-bottom: 30px; /* Space between each post */
  padding: 20px;
}
.figure-post__header .h3 a {
  text-decoration: none; /* Remove the default underline */
  position: relative;
  display: inline-block;
}

/* Hover effect - underline */
.figure-post__header .h3 a:hover {
  text-decoration: none; /* Ensure there is no default underline */
}

/* Style for the red circle that follows the mouse */
.mouse-circle {
  position: absolute;
  width: 50px; /* Size of the circle */
  height: 50px; /* Size of the circle */
  border-radius: 50%; /* Makes it round */
  background-color: transparent; /* Red color */
  border:1px solid red ;
  pointer-events: none; /* Prevent the circle from interfering with other elements */
  transform: translate(-50%, -50%); /* Centers the circle on the cursor */
  transition: all 0.4s ease; /* Smooth transition effect */
}

.mouse-circle.expanded {
  width: 100px; /* Set the expanded size */
  height: 100px; /* Set the expanded size */
  background-color: red; /* Red background when hovering over the image */
  opacity: 1; /* Make it visible */
  color: white; /* Change text color to white */
  font-size: 14px; /* Adjust font size */
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease; /* Smooth transition for expansion */
}

.mouse-circle.expanded:after {
  margin-top: 35px;
  content: "Read More"; /* Text inside the circle */
  display: flex;
  justify-content: center; /* Horizontally center the text */
  align-items: center; /* Vertically center the text */
  text-align: center; /* Ensures the text is centered inside the circle */
  font-weight: bold; /* Make the text bold */
  color: white; /* Set text color to white */
  font-size: 14px; /* Adjust font size */
}


/* Underline effect on hover */
.figure-post__header .h3 a:after {
  content: '';
  position: absolute;
  bottom: -2px; /* Adjust the position of the underline */
  left: 0;
  width: 100%;
  height: 2px; /* Adjust thickness of the underline */
  background-color: #000; /* Color of the underline */
  transform: scaleX(0);
  transform-origin: bottom right;
  transition: transform 0.3s ease-out;
}

.figure-post__header .h3 a:hover:after {
  transform: scaleX(1);
  transform-origin: bottom left;
}
.button.button_bordered {
  background-color: unset !important;
  color: var(--color-dark-1);
  border-width: 1px;
  border-style: solid;
}
.blog-post__image img {
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-radius: 8px;
}

.blog-post__content {
  padding: 10px 0;
}

.blog-post__title {
  font-size: 1.8rem;
  margin-bottom: 10px;
  color: #333;
}

.blog-post__description {
  font-size: 1rem;
  color: #555;
  margin-bottom: 20px;
}

.blog-post__meta {
  font-size: 0.9rem;
  color: #888;
}

.blog-post__author {
  margin-right: 10px;
}

.blog-post__date-time {
  font-style: italic;
}

/* Footer styles */
.footer {
  text-align: center;
  padding: 20px;
  background-color: #333;
  color: white;
  width: 100%;
  position: relative;
  bottom: 0;
}

.footer p {
  margin: 0;
}
.content-overlay {
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent overlay for readability */
  padding: 20px;
  border-radius: 10px;
}
.blog-detail-container {
  font-family: 'Arial', sans-serif;
  padding: 20px;
}

/* Header with background image */
.blog-detail-header {
  position: relative;
  height: 400px;
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  text-align: center;
}

/* Overlay for better text contrast */
.blog-detail-header .overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.4); /* Darken the image for better text visibility */
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Blog title styling */
.blog-title {
  font-size: 3rem;
  font-weight: bold;
  margin: 0;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5); /* Subtle text shadow */
}

/* Blog content section */
.blog-content {
  max-width: 900px;
  margin: 40px auto;
  padding: 20px;
  background-color: #fff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

/* Paragraph styling */
.blog-content p {
  font-size: 1.2rem;
  line-height: 1.8;
  color: #333;
}

/* Additional styling for better readability */
.blog-content h2 {
  font-size: 2rem;
  font-weight: 600;
  margin-top: 30px;
}

.blog-content ul {
  list-style-type: disc;
  padding-left: 20px;
}

.blog-content ul li {
  margin-bottom: 10px;
}

.blog-content blockquote {
  background-color: #f9f9f9;
  padding: 15px;
  border-left: 5px solid #ddd;
  margin-top: 20px;
  font-style: italic;
}

@media (max-width: 768px) {
  .blog-detail-header {
    height: 250px;
  }

  .blog-title {
    font-size: 2rem;
  }
}

.container, .container-fluid, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
  width: 100%;
  padding-right: var(--bs-gutter-x, .75rem);
  padding-left: var(--bs-gutter-x, .75rem);
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 992px) {
  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.6666666667%;
  }
}
.row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex
;
  flex-wrap: wrap;
  margin-top: calc(var(--bs-gutter-y)* -1);
  margin-right: calc(var(--bs-gutter-x) / -2);
  margin-left: calc(var(--bs-gutter-x) / -2);
}
.justify-content-center {
  justify-content: center !important;
}
ol > li:before {
  display:none
}