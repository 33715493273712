.hero-heading{
    font-family: 'Nexa Bold';
}

.hero-subheading{
    font-family: 'Urbanist Light';
} 


.arrow3{
  display: none;
}

@media screen and (max-width: 768px){
   
  .arrow3{
    display: block;
    height: 150px;
    position: relative;
    margin-top: -90px;

    margin-bottom: 10px;
  }
    .div1{
        display: none;
    }

    .mainpart{
        margin-bottom: 10px;
        padding: 0px;   
    }
}


@keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  .fade-in {
    animation: fadeIn 2s ease-in;
  }

  
  @keyframes slideInFromLeft {
    from {
      transform: translateX(-100%);
    }
    to {
      transform: translateX(0);
    }
  }
  
  .slide-in-left {
    animation: slideInFromLeft 1s ease-out;
  }

  @keyframes slideInFromRight {
    from {
      transform: translateX(100%);
    }
    to {
      transform: translateX(0);
    }
  }
  
  .slide-in-right {
    animation: slideInFromRight 1s ease-out;
  }

  
  @keyframes rotate {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  
  .rotate {
    animation: rotate 1s linear;
  }
  