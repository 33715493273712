.navbar a{
    color: rgb(0, 0, 0);
    text-decoration: none;
    display: block;
    text-align: center;
    font-size: 17px;
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    transition: 0.3s;
}

.navbar {
    padding: 5px 10px;
  }
  
  .menu-icon {
    display: flex;
    flex-direction: column;
    cursor: pointer;
  }
  
  .icon-bar {
    width: 25px;
    height: 3px;
    background-color: black;
    margin: 4px 0;
    transition: 0.4s;
  }
  
  /* Hide menu links on mobile by default */
  .menu-links {
    display: none;
    flex-direction: column;
    position: absolute;
    top: 70px; /* Adjust based on your navbar height */
    right: 20px;
    background-color: white;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
  }
  
  /* Show menu links when open */
  .menu-links.open {
    display: flex;
  }
  
  .menu-links a {
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    color: black;
    border-bottom: 1px solid #ddd;
  }
  
  .menu-links a:last-child {
    border-bottom: none;
  }

  @media (min-width: 768px) {
    .menu-icon {
      display: none;
    }

    .icontop{
        margin-left: 100px;
    }

    
    .menu-links {
      display: flex !important;
      position: static;
      flex-direction: row;
      background-color: transparent;
      box-shadow: none;
      border-radius: 0;
      margin-right: 40px;
    }
    
    .menu-links a {
      border: none;
      padding: 0 10px;
    }
  }
  