.containermain2{
    display: none;
}

.container1 {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 80vh;
    overflow: hidden;
    position: relative;
    
}

.image {
    z-index: 2;
    position: absolute;
    margin-top: -80px;
    height: 600px;
    left: 10%;
    pointer-events: none;
    /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    border-radius: 20px; */
    transition: opacity 0.5s ease-in-out;
}

.text {
    width: 100%; /* Adjust based on your layout needs */
    height: 50vh;
    scrollbar-width: none; /* Firefox */
    padding: 20px;
    background-color: #F5FCE7;
    z-index: 1;
    justify-content: end;
    display: flex;
    transition: transform 0.5s ease-in-out;
    
    align-items: center;
}

.subtext2{
    width: 50%;
    transition: transform 2s ease-in-out;
    animation: fadeIn 1s;
}

.slide {
    height: 50vh; /* Each slide fills the viewport height */
    display: flex;
    align-items: center;
    justify-content: center;
    scroll-snap-align: start;
    width: 50%;
    margin-left: auto;
    
}

.Display {
    height: 450px;
}


@media screen and (max-width: 768px){

    .containermain{
        display: none;
    }
    .containermain2{
        display: block;
        align-items: center;
        margin-top: 0px;
    }
    .container1 {
        display: flex;
        align-items: center;
        flex-direction: column;
        height: 100vh;
    }
    .image {
        visibility: hidden;
    }

    .slide{
        width: 100%;
    }

    .text {
        display: block;
    }
}




@keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  .fade-in {
    animation: fadeIn 1s ease-in;
  }

  
  @keyframes slideInFromLeft {
    from {
      transform: translateX(-100%);
    }
    to {
      transform: translateX(0);
    }
  }
  
  .slide-in-left {
    animation: slideInFromLeft 1s ease-out;
  }

  @keyframes slideInFromRight {
    from {
      transform: translateX(100%);
    }
    to {
      transform: translateX(0);
    }
  }
  
  .slide-in-right {
    animation: slideInFromRight 1s ease-out;
  }

  
  @keyframes rotate {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  
  .rotate {
    animation: rotate 1s linear;
  }
  