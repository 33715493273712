.hero-heading{
    font-family: 'Nexa Bold';
}

.hero-subheading{
    font-family: 'Urbanist Light';
} 

.btn {
    font-family: 'Urbanist Light';
    color:black;
    background-color: #E8FFCB;
    font-weight: 700;
}

.arrow2 {
    display: none;
}

@media screen and (max-width: 768px){
  .arrow2{
    display: block;
    height: 150px;

    }
    .hero-heading{
        font-size: 2rem;
    }

    .hero-subheading{
        font-size: 1.5rem;
    }

    .herocontainer{
        padding: 0 0px;
        flex-direction: column;
    }
    .hero {
        padding: 0 0px;
        margin-bottom: 100px;
    }
    .arrow{
        display: none;
    }

    .hero-text{
        width: 100%;
        margin-left: 0px;
    }

    .hero-image {
        margin-left: 0px;
    }
    
}

@media screen and (min-width: 2300px){
    .hero-subheading{
        font-size: 3rem;
        line-height: 1.5;
    }
}


@keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  
  .fade-in {
    animation: fadeIn 2s ease-in;
  }

  @keyframes slideInFromTop {
    from {
      transform: translateY(-100%);
    }
    to {
      transform: translateY(0);
    }
  }

  .slide-in-top {
    animation: slideInFromTop 1s ease-out;
  }

  
  @keyframes slideInFromLeft {
    from {
      transform: translateX(-100%);
    }
    to {
      transform: translateX(0);
    }
  }
  
  .slide-in-left {
    animation: slideInFromLeft 1s ease-out;
  }

  @keyframes slideInFromRight {
    from {
      transform: translateX(100%);
    }
    to {
      transform: translateX(0);
    }
  }
  
  .slide-in-right {
    animation: slideInFromRight 1s ease-out;
  }

  
  @keyframes rotate {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  
  .rotate {
    animation: rotate 1s linear;
  }
  