/* body {
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont,
    Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif,
    Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
} */

.container {
  padding: 4rem;
  padding-top: 0rem;
}

.privacy-header {
  margin: 2.5rem;
}
.heading1 {
  font-size: 3.75rem;
  font-weight: 900;
  /* line-height: 2rem; */
}

.date {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.list-container {
  margin: 2.5rem;
  margin-top: 8rem;
  padding-right: 4rem;
  text-wrap: pretty;
}

ol {
  counter-reset: item;
}

ol > li {
  display: block;
}
ol > li:before {
  content: counters(item, ".") " ";
  counter-increment: item;
}

ul > li {
  /* list-style-type: circle; */
  list-style: inside;
}

.ul-item + .ul-item {
  margin-top: 1rem;
}

.list-headings {
  font-size: 2rem;
  font-weight: 900;
  line-height: 2.25rem;
  margin-bottom: 4rem;
}

.list-content {
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 1.75rem;
  margin-bottom: 4rem;
  margin-top: 1.75rem;
}

.list-content > li::before {
  font-weight: 700;
}

.content-text + .content-text {
  margin-top: 1.75rem;
}

@media (max-width: 768px) {
  .container {
    padding: 2rem;
  }
  .privacy-header {
    margin: 1rem;
    padding-right: 2rem;
  }
  .heading1 {
    font-size: 3rem;
    font-weight: 900;
    /* line-height: 2rem; */
  }
  .date {
    font-size: 1rem;
    line-height: 1.5rem;
  }

  .list-container {
    margin: 1rem;
    margin-top: 4rem;
    padding-right: 0rem;
  }
  .list-headings {
    font-size: 1.5rem;
    line-height: 1.75rem;
    margin-bottom: 2rem;
  }
  .list-content {
    font-size: 1rem;
    line-height: 1.5rem;
  }
}

@media (min-width: 3400px) {
  .container {
    padding: 4rem;
  }
  .privacy-header {
    margin: 4rem;
    padding-right: 4rem;
  }
  .heading1 {
    font-size: 6rem;
    font-weight: 900;
    /* line-height: 2rem; */
  }
  .date {
    font-size: 2rem;
    line-height: 2rem;
  }

  .list-container {
    margin: 4rem;
    margin-top: 8rem;
    padding-right: 4rem;
  }

  .list-headings {
    font-size: 3rem;
    line-height: 3.5rem;
    margin-bottom: 4rem;
  }
  .list-content {
    font-size: 2rem;
    line-height: 2.5rem;
  }
}
