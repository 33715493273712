@media screen and (max-width: 768px){ 
    

    .cont {
        padding: 0px;
    }

    .mob {
        margin-bottom: 100px;
    }
}

@keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  .fade-in {
    animation: fadeIn 2s ease-in;
  }

  
  @keyframes slideInFromLeft {
    from {
      transform: translateX(-100%);
    }
    to {
      transform: translateX(0);
    }
  }
  
  .slide-in-left {
    animation: slideInFromLeft 1s ease-out;
  }

  @keyframes slideInFromRight {
    from {
      transform: translateX(100%);
    }
    to {
      transform: translateX(0);
    }
  }
  
  .slide-in-right {
    animation: slideInFromRight 1s ease-out;
  }

  
  @keyframes rotate {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  
  .rotate {
    animation: rotate 1s linear;
  }
  